import TextLink from "../../components/TextLink";
import NavButton from "../../layouts/NavButton";
import PageTitle from "../../components/PageTitle";
import MapContainer from "./components/MapContainer";
import "../styles.css";
import { data } from "./data/data.js";

function Tisser() {
    const titre = "Tisser";
    const text = (
        <div>
            <p>
                Une carte partielle et partiale des{" "}
                <span className="font-bold">émergences en santé.</span>
            </p>
            <br />
            <p className="italic">
                "La carte n'est pas le territoire, mais elle aide à se repérer."
            </p>
            <br />
            <p>
                Santé intégrative, planétaire, communautaire, quel que soit le
                nom : <br /> des personnes, lieux, associations, collectifs,
                médias, qui esquissent un visage de la santé en recomposition.
            </p>
            <br />
            <p>
                Soutenir l'existant ou nourrir l'émergent, peu importe ! Du
                moment que l'intention de prendre soin est là.
            </p>
        </div>
    );

    return (
        <div className="bg-slate-50">
            <div className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>
                <div className="grid-item w-full">
                    <MapContainer data={data} />
                </div>
                <div className="grid-item w-full">
                    <p className="font-text text-justify">
                        Cette carte n'aurait jamais existé si je n'avais pas
                        fait tout ce <TextLink link={"/"} title={"chemin"} />.{" "}
                        <TextLink link={"/douter"} title={"Douter"} /> tout
                        d'abord du sens de mon métier, jusqu'à{" "}
                        <TextLink link={"/renoncer"} title={"renoncer"} /> à
                        l'exercer voire à exercer tout court. Et puis, de
                        rencontre en rencontre ; de dialogue, en chemin, en
                        pause, en réflexion, en échange, en soin, j'ai renoncé à
                        attendre qu'une solution vienne "d'en haut", des
                        "représentant·es". J'ai{" "}
                        <TextLink link={"/faire-corps"} title={"fait corps"} />{" "}
                        avec d'autres. C'est ainsi que cette carte est née pour
                        tenter de tisser entre toutes les personnes de terrain
                        qui cherchent à faire de la santé un bien commun, et du
                        soin une intention de faire société.
                    </p>
                </div>
                <NavButton />
            </div>
        </div>
    );
}

export default Tisser;
