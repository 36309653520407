import React, { useMemo, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useGesture } from "@use-gesture/react";
import Petal from "../Petal";
import { getPetalPosition } from "../../utils/positionUtils";

const MobileMapWithGesture = ({ data, isFullscreen }) => {
    const themes = [...new Set(data.map((item) => item.theme))];
    const centerTheme = "Faire corps";
    const surroundingThemes = themes.filter((theme) => theme !== centerTheme);

    // Dimensions based on mode
    const frameWidth = "100%";
    const frameHeight = isFullscreen ? "100%" : 300;
    const mapWidth = 750;
    const mapHeight = 500;

    // Track position and scale separately for more precise control
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(isFullscreen ? 0.5 : 0.35);

    const petalPositions = useMemo(() => {
        return surroundingThemes.map((_, index) =>
            getPetalPosition(
                index,
                surroundingThemes.length,
                mapWidth,
                mapHeight,
            ),
        );
    }, [surroundingThemes.length, mapWidth, mapHeight]);

    // Spring for smooth animations
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: isFullscreen ? 0.5 : 0.35,
        config: { tension: 300, friction: 30 },
        transform: 'translate(-50%, -50%) translate(0px, 0px) scale(0.5)'
    }));

    const bind = useGesture({
        onDrag: ({ movement: [mx, my], first, memo = position }) => {
            if (first) return memo;

            const newPosition = {
                x: memo.x + my,
                y: memo.y - mx
            };
            
            setPosition(newPosition);
            api.start({ 
                x: newPosition.x,
                y: newPosition.y,
                transform: `translate(-50%, -50%) translate(${newPosition.x}px, ${newPosition.y}px) scale(${scale})`,
                immediate: true
            });
            
            return memo;
        },
        onPinch: ({ 
            origin: [ox, oy],
            first, 
            movement: [ms], 
            offset: [currentScale],
            memo
        }) => {
            if (first) {
                // Store the initial position and scale
                return { 
                    initScale: scale,
                    initPos: position,
                    origin: { x: ox, y: oy }
                };
            }

            // Calculate new scale (clamped between 0.5 and 4)
            const newScale = Math.min(Math.max(0.5, currentScale), 4);

            // Swap and invert axes for the pinch origin point
            const dx = (oy - window.innerHeight / 2);
            const dy = -(ox - window.innerWidth / 2);
            
            // Adjust position based on pinch origin and scale change
            const scaleChange = newScale / memo.initScale;
            const newPosition = {
                x: memo.initPos.x + (dx * (1 - scaleChange)),
                y: memo.initPos.y + (dy * (1 - scaleChange))
            };

            setScale(newScale);
            setPosition(newPosition);
            
            api.start({ 
                scale: newScale,
                x: newPosition.x,
                y: newPosition.y,
                transform: `translate(-50%, -50%) translate(${newPosition.x}px, ${newPosition.y}px) scale(${newScale})`,
                immediate: true
            });

            return memo;
        }
    }, {
        drag: {
            from: () => [position.x, position.y]
        },
        pinch: {
            scaleBounds: { min: 0.5, max: 4 },
            rubberband: true
        }
    });

    return (
        <div
            className="frame relative mx-auto overflow-hidden"
            style={{
                width: frameWidth,
                height: frameHeight,
                border: "2px solid black",
                touchAction: "none",
            }}
            {...bind()}
        >
            <animated.div
                className="mind-map absolute"
                style={{
                    width: mapWidth,
                    height: mapHeight,
                    left: "50%",
                    top: "50%",
                    transform: style.transform
                }}
            >
                {/* Center Petal */}
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Petal
                        theme={centerTheme}
                        data={data}
                        key={centerTheme}
                        ellipseWidth={300}
                        ellipseHeight={190}
                        scale={scale}
                        isCenter={true}
                    />
                </div>

                {/* Surrounding Petals */}
                {surroundingThemes.map((theme, index) => {
                    const position = petalPositions[index];
                    return (
                        <div
                            key={theme}
                            className="absolute"
                            style={{
                                left: `${position.x + mapWidth / 2}px`,
                                top: `${position.y + mapHeight / 2}px`,
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Petal
                                key={theme}
                                theme={theme}
                                data={data}
                                ellipseWidth={200}
                                ellipseHeight={130}
                                scale={scale}
                            />
                        </div>
                    );
                })}
            </animated.div>
        </div>
    );
};

export default MobileMapWithGesture;
