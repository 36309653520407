const legendData = [
    { icon: "BiSolidCameraMovie", legend: "Vidéo" },
    { icon: "BsHouseFill", legend: "Lieu" },
    { icon: "FaPeopleGroup", legend: "Collectif, association" },
    { icon: "FaUserPen", legend: "Société savante" },
    { icon: "FcReading", legend: "Livre, article" },
    { icon: "GiWorld", legend: "Carte" },
    { icon: "GrPersonalComputer", legend: "Numérique" },
    { icon: "HiMicrophone", legend: "Podcast" },
    { icon: "IoIosSchool", legend: "Formation, école" },
    { icon: "PiEyesFill", legend: "Média" },
];

export default legendData;
