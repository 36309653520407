function invertColor(rgb) {
    const colorList = rgb.split(",");
    const invertedR = 255 - colorList[0];
    const invertedG = 255 - colorList[1];
    const invertedB = 255 - colorList[2];
    return `rgb(${invertedR}, ${invertedG}, ${invertedB})`;
}

function SmallCard({ title, content, rgb }) {
    return (
        <div
            className="flex flex-col rounded p-2 sm:p-3 md:hidden"
            style={{ backgroundColor: `rgba(${rgb}, 1)` }}
        >
            <div className="grid gap-2 p-4 md:w-2/3 md:gap-4">
                <h1
                    className="font-subtitle text-md text-primary-bold sm:text-l pb-4 font-semibold uppercase"
                    // style={{ color: `${invertColor(rgb)}` }}
                >
                    {title}
                </h1>

                <div className="font-text text-sm leading-relaxed md:text-base">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default SmallCard;
