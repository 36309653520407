import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import DynamicIcon from "../DynamicIcon";
import legendData from "../../data/legendData";

const MapModal = ({ isOpen, onClose, children, isMobileView }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        }
        return () => {
            document.body.style.overflow = "unset";
            document.body.style.touchAction = "auto";
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return createPortal(
        <div
            className="fixed inset-0 z-[9999] bg-black bg-opacity-75 transition-all duration-300"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}
        >
            {/* Close button */}
            <button
                onClick={onClose}
                className="absolute right-4 top-4 z-[10000] rounded-full bg-white p-2 text-black shadow-lg"
                aria-label="Close modal"
            >
                <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

            {/* Modal content - landscape for mobile */}
            <div
                className="flex h-screen w-screen items-center justify-center overflow-hidden bg-white"
                style={
                    isMobileView
                        ? {
                              width: "100vh",
                              height: "100vw",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%) rotate(90deg)",
                              transformOrigin: "center center",
                              touchAction: "none",
                              overflow: "hidden",
                          }
                        : {
                              width: "100%",
                              height: "100%",
                          }
                }
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        touchAction: "none",
                        overflow: "hidden",
                    }}
                >
                    {children}
                    {/* Legend */}
                    <div
                        style={{
                            position: "relative",
                            bottom: "0.5rem",
                            left: "50%",
                            transform: "translate(-50%, -100%)",
                            padding: "5px 10px",
                            borderRadius: "10px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "white",
                            width: "80%",
                            zIndex: 10,
                            display: "flex",
                        }}
                    >
                        <div className="flex flex-row flex-wrap justify-evenly gap-2 font-sans text-sm">
                            {legendData.map((theme, index) => (
                                <ul className="flex flex-row items-center gap-1">
                                    <DynamicIcon iconName={theme.icon} />
                                    <label>{theme.legend}</label>
                                </ul>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body,
    );
};

export default MapModal;
