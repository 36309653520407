import React from "react";
import { IconMapper } from "../../utils/iconMapper";

const DynamicIcon = ({ iconName }) => {
    // Check if the iconName exists in the mapper

    const IconComponent = IconMapper[iconName];

    if (!IconComponent) {
        console.warn(`Icon not found: ${iconName}`); // Add warning for debugging
        return <p>{iconName}</p>;
    }

    return <IconComponent size="1.2em" />; // Add consistent size
};

export default DynamicIcon;
