import React, { useMemo, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Petal from "../Petal";
import { getPetalPosition } from "../../utils/positionUtils";

const Map = ({ data, isMobileView }) => {
    const [isPanning, setIsPanning] = useState(false);
    const [scale, setScale] = useState(1);
    const themes = [...new Set(data.map((item) => item.theme))];
    const centerTheme = "Faire corps";
    const surroundingThemes = themes.filter((theme) => theme !== centerTheme);

    const frameWidth = "100%";
    const frameHeight = 750;
    const mapWidth = 750;
    const mapHeight = 500;

    const petalPositions = useMemo(() => {
        return surroundingThemes.map((_, index) =>
            getPetalPosition(
                index,
                surroundingThemes.length,
                mapWidth,
                mapHeight,
            ),
        );
    }, [surroundingThemes.length, mapWidth, mapHeight]);

    return (
        <div
            className="frame relative mx-auto overflow-hidden"
            style={{
                width: frameWidth,
                height: frameHeight,
                border: "2px solid black",
                touchAction: "none", // Désactive le comportement natif des gestes tactiles
            }}
        >
            <TransformWrapper
                initialScale={isMobileView ? 0.5 : 1}
                minScale={0.5}
                maxScale={4}
                centerOnInit={true}
                wheel={{ smoothStep: 0.005 }}
                pinch={{ disabled: false }}
                panning={{
                    velocityDisabled: true,
                    lockAxisX: false,
                    lockAxisY: false,
                    disabled: false
                }}
                doubleClick={{ disabled: true }}
                alignmentAnimation={{ disabled: true }}
                limitToBounds={false}
                centerZoomedOut={false}
                initialPositionY={isMobileView ? -200 : -125}
                initialPositionX={isMobileView ? 0 : 0}
                onPanning={(e) => {
                    setIsPanning(true);
                    if (isMobileView) {
                        // When in mobile view, we need to swap and invert the axes
                        // because the view is rotated 90 degrees
                        e.setTransform({
                            x: e.state.x + e.deltaY, // Vertical movement becomes horizontal
                            y: e.state.y - e.deltaX, // Horizontal movement becomes vertical
                            scale: e.state.scale
                        });
                        // e.preventDefault();
                    }
                }}
                onPanningStop={() => setIsPanning(false)}
                onTransformed={(e) => setScale(e.state.scale)}
            >
                <TransformComponent
                    wrapperStyle={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className={`mind-map ${isPanning ? "cursor-grabbing" : "cursor-grab"}`}
                        style={{
                            width: mapWidth,
                            height: mapHeight,
                            position: "relative",
                            left: "50%",
                            top: "50%",
                            transform: isMobileView
                                ? "translate(-50%, -50%)"
                                : "translateX(-50%)",
                        }}
                    >
                        {/* Center Petal */}
                        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                            <Petal
                                theme={centerTheme}
                                data={data}
                                key={centerTheme}
                                ellipseWidth={300}
                                ellipseHeight={190}
                                scale={scale}
                                isCenter={true}
                            />
                        </div>

                        {/* Surrounding Petals */}
                        {surroundingThemes.map((theme, index) => {
                            const position = petalPositions[index];

                            return (
                                <div
                                    key={theme}
                                    className="absolute"
                                    style={{
                                        left: `${position.x + mapWidth / 2}px`,
                                        top: `${position.y + mapHeight / 2}px`,
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <Petal
                                        key={theme}
                                        theme={theme}
                                        data={data}
                                        ellipseWidth={200}
                                        ellipseHeight={130}
                                        scale={scale}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default Map;
