// IconMapper.js
import { BiSolidCameraMovie } from "react-icons/bi";
import { BsHouseFill } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaUserPen } from "react-icons/fa6";
import { FcReading } from "react-icons/fc";
import { GiWorld } from "react-icons/gi";
import { GrPersonalComputer } from "react-icons/gr";
import { HiMicrophone } from "react-icons/hi";
import { IoIosSchool } from "react-icons/io";
import { PiEyesFill } from "react-icons/pi";

export const IconMapper = {
    BiSolidCameraMovie: BiSolidCameraMovie,
    BsHouseFill: BsHouseFill,
    FaPeopleGroup: FaPeopleGroup,
    FaUserPen: FaUserPen,
    FcReading: FcReading,
    GiWorld: GiWorld,
    GrPersonalComputer: GrPersonalComputer,
    HiMicrophone: HiMicrophone,
    IoIosSchool: IoIosSchool,
    PiEyesFill: PiEyesFill,
};
