import React, { useState, useEffect } from "react";
import Map from "../Map";
import MobileMapWithGesture from "../MobileMapWithGesture";
import MapModal from "../MapModal";
import DynamicIcon from "../DynamicIcon";
import legendData from "../../data/legendData";

const MapContainer = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const checkMobileView = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        // Initial check
        checkMobileView();

        // Add event listener for window resize
        window.addEventListener("resize", checkMobileView);

        // Cleanup
        return () => window.removeEventListener("resize", checkMobileView);
    }, []);

    return (
        <>
            {/* Initial view */}
            <div className={`relative ${isMobileView ? "cursor-pointer" : ""}`}>
                {isMobileView ? (
                    <>
                        <MobileMapWithGesture
                            data={data}
                            isFullscreen={false}
                        />
                        {/* Fullscreen button overlay - mobile only */}
                        <div
                            onClick={() => setIsModalOpen(true)}
                            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20"
                        >
                            <span className="rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-800">
                                Cliquer ici pour afficher en plein écran
                            </span>
                        </div>
                    </>
                ) : (
                    <Map data={data} isMobileView={false} />
                )}
            </div>

            {/* Legend */}
            <div
                style={{
                    position: "relative",
                    bottom: "0.5rem",
                    left: "50%",
                    transform: "translate(-50%, -100%)",
                    padding: "5px 10px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    width: "calc(100% - 20px)",
                    zIndex: 10,
                    display: `${isMobileView ? "none" : "inline-block"}`,
                }}
            >
                <div className="flex flex-row flex-wrap justify-evenly gap-2 font-sans text-sm">
                    {legendData.map((theme, index) => (
                        <ul className="flex flex-row items-center gap-1">
                            <DynamicIcon iconName={theme.icon} />
                            <label>{theme.legend}</label>
                        </ul>
                    ))}
                </div>
            </div>

            {/* Modal for expanded view */}
            <MapModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                isMobileView={isMobileView}
            >
                {isMobileView ? (
                    <MobileMapWithGesture data={data} isFullscreen={true} />
                ) : (
                    <Map data={data} isMobileView={true} />
                )}
            </MapModal>
        </>
    );
};

export default MapContainer;
