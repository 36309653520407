function ImageCard({ title, content, src, alt, imagePosition, rgb }) {
    let backgroundImage;

    switch (imagePosition) {
        case "right":
            backgroundImage = `linear-gradient(to right, rgba(${rgb}, 1) 50%, rgba(${rgb}, 0) 100%), url(${src})`;
            break;
        case "left":
            backgroundImage = `linear-gradient(to left, rgba(${rgb}, 1) 50%, rgba(${rgb}, 0) 100%), url(${src})`;
            break;
        case "center":
            backgroundImage = `url(${src})`;
            break;
        case "poem":
            backgroundImage = `linear-gradient(to right, rgba(${rgb}, 1) 30%, rgba(${rgb}, 0) 100%), url(${src})`;
            break;
        default:
            backgroundImage = `url(${src})`;
            break;
    }
    return (
        <div
            className={`hidden rounded bg-white p-2 sm:p-3 md:flex ${
                imagePosition === "left"
                    ? "md:flex-row-reverse"
                    : imagePosition === "right"
                      ? "md:flex-row"
                      : "items-center justify-center"
            } md:rounded-lg md:p-4`}
            style={{
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="grid gap-2 p-4 md:w-2/3 md:gap-4">
                <h1 className="font-subtitle text-primary-bold sm:text-l pb-4 font-bold uppercase md:p-0 md:text-xl">
                    {title}
                </h1>

                <div
                    loading="lazy"
                    alt={alt}
                    className={`font-text text-justify text-sm md:text-base ${
                        imagePosition === "center" ? "text-white" : ""
                    }`}
                >
                    {content}
                </div>
            </div>
        </div>
    );
}

export default ImageCard;
