import ContactForm from "./components/ContactForm";
import TextLink from "../../../components/TextLink";
import "../../styles.css";

function Contact() {
    const titre = "Contact";

    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand justify-center pb-4 text-center text-4xl sm:text-5xl">
                        {titre}
                    </h1>
                </div>
                <div className="grid-item font-text leading-relaxed md:text-justify md:leading-normal">
                    <p>
                        En deux mots, je m'appelle Margot Smirdec, j'étais
                        médecin anesthésiste-réanimatrice. J'ai beaucoup douté
                        du sens de mon métier, jusqu'à renoncer à l'exercer
                        voire à exercer tout court. Rendre la blouse.
                    </p>
                    <p>
                        Et puis, de rencontre en rencontre ; de dialogue, en
                        chemin, en pause, en réflexion, en échange, en soin,
                        j'ai renoncé à attendre qu'une solution vienne "d'en
                        haut", des "représentant·es". J'ai compris que le
                        tissage viendrait du terrain. Voilà donc que j'y
                        retourne en reprenant un internat de psychiatrie, en
                        continuant de nourrir les liens avec les différents
                        écosystèmes qui essayent d'émerger pour faire du soin et
                        de la santé globale un bien commun.
                    </p>
                    <br />
                    <p>
                        Pour me contacter, vous pouvez me trouver sur{" "}
                        <TextLink
                            title={"LinkedIn"}
                            link={
                                "https://fr.linkedin.com/in/margot-smirdec-264023213"
                            }
                        />{" "}
                        et{" "}
                        <TextLink
                            title={"Instagram"}
                            link={
                                "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fmon_blanc_de_travail%2F&is_from_rle"
                            }
                        />{" "}
                        ou bien laisser un message ici. Je ferai de mon mieux
                        pour vous lire dès que j'aurai l'espace et vous répondre
                        autant que possible.{" "}
                    </p>
                    <br />
                    <p>
                        Je vous invite à rentrer en lien avec les communautés
                        qui vous inspirent parmi celles représentées sur la
                        carte mentale de la page{" "}
                        <TextLink title={"Tisser"} link={"/tisser"} /> ou bien
                        celles de la page{" "}
                        <TextLink title={"Faire Corps"} link={"/faire-corps"} />
                        . C'est vraiment l'intention de ce site : tisser,
                        contribuer à rendre visible, nourrir les élans qui
                        aimeraient se lancer. Et comme cette carte est loin
                        d'être exhaustive, n'hésitez pas à chercher également
                        près de chez vous ou sur le net, vous en trouverez
                        sûrement d'autres.
                    </p>
                    <br />
                    <p>
                        Si vous aimeriez ajouter un lien sur la carte mentale,
                        laissez moi un message ici, je ferai de mon mieux dans
                        la limite de mon temps et de celui des personnes qui
                        m'aident et m'accompagnent. Dans tous les cas, cette
                        carte n'a pas vocation à être exhaustive, simplement à
                        essayer de contribuer à sa façon.
                    </p>
                </div>

                <div className="grid-item flex gap-4 text-xl">
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}

export default Contact;
