import "../../styles.css";
import TextLink from "../../../components/TextLink";

function Partenaires() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Partenaires
                    </h1>
                    <p className="text-center">Version du 30 janvier 2025</p>

                    <h3 className="font-text py-4 text-2xl">
                        Développement du site
                    </h3>
                    <p>
                        Le site a été conçu et développé par Bogdan SMIRDEC, mon
                        frère, en utilisant les technologies ReactJS et
                        TailwindCSS.
                        <br />
                        Merci aux ressources en ligne et communautés pour leur
                        soutien et inspiration.
                        <br />
                        Mentions particulières aux sites de{" "}
                        <TextLink
                            link={"https://santeplanetaire.org/"}
                            title={"l'Alliance Santé Planétaire"}
                        />
                        , ainsi que celui de{" "}
                        <TextLink
                            link={"https://www.mbsr-paris.fr/"}
                            title={"Soizic Michelot"}
                        />
                        , dont nous nous sommes particulièrement inspirés.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Police du site</h3>
                    <p>
                        La police principale du site est la police 'Luciole',
                        développée par le centre technique régional pour la
                        déficience visuelle et le studio{" "}
                        <TextLink
                            link={"https://typographie.fr/"}
                            title={"typographie.fr"}
                        />
                        , disponible au téléchargement sous licence{" "}
                        <TextLink
                            link={
                                "https://creativecommons.org/licenses/by/4.0/legalcode.fr"
                            }
                            title={"Créative Commons Attribution"}
                        />
                        . Plus d'infos sur le site{" "}
                        <TextLink
                            link={"https://www.luciole-vision.com/#top"}
                            title={"www.luciole-vision.com"}
                        />
                        .
                    </p>

                    <h3 className="font-text py-4 text-2xl">Illustrations</h3>
                    <p>
                        Les illustrations utilisées sur ce site proviennent de
                        sources libres de droits.
                        <br />
                        Merci aux artistes pour leur travail inspirant.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Photos</h3>
                    <p>
                        Les photos présentes sur le site proviennent de banques
                        d'images libres de droits ou directement de ma
                        bibliothèque personnelle.
                    </p>

                    <h3 className="font-text py-4 text-2xl">Remerciements</h3>
                    <p>
                        Je tiens à remercier toutes les personnes et communautés
                        qui ont contribué, directement ou indirectement, à la
                        réalisation de ce site. Votre aide et votre soutien ont
                        été précieux.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Partenaires;
