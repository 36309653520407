function LinkCard({ title, content }) {
    return (
        <div
            className={`flex flex-col rounded bg-white p-2 sm:p-3 md:flex-row md:rounded-lg md:p-4`}
        >
            <div className="grid gap-4 p-4">
                <h1 className="font-subtitle text-primary-extrabold pb-2 text-center text-xl font-bold uppercase sm:text-xl md:text-2xl">
                    {title}
                </h1>

                <div className="font-text text-sm leading-relaxed text-slate-700 md:text-justify md:text-base md:leading-normal">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default LinkCard;
