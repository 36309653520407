import p16 from "../../../assets/images/douter/medium/p16.webp";
import p57 from "../../../assets/images/douter/medium/p57.webp";
import p71 from "../../../assets/images/douter/medium/p71.webp";
import p91 from "../../../assets/images/douter/medium/p91.webp";
import p98 from "../../../assets/images/douter/medium/p98.webp";
import p113 from "../../../assets/images/douter/medium/p113.webp";
import p139 from "../../../assets/images/douter/medium/p139.webp";
import p157 from "../../../assets/images/douter/medium/p157.webp";
import p175 from "../../../assets/images/douter/medium/p175.webp";
import p227 from "../../../assets/images/douter/medium/p227.webp";
import p261 from "../../../assets/images/douter/medium/p261.webp";
import p265 from "../../../assets/images/douter/medium/p265.webp";
import p319 from "../../../assets/images/douter/medium/p319.webp";

const bookExtracts = [
    {
        title: "P16 - Serment d’Hippocrate ou d’hypocrite - Vendredi 13 mars 2020",
        content: (
            <p>
                Je me demande si ce serment d’Hippocrate n’était pas hypocrite
                et s’il n’est pas temps que je rende mon blanc de travail, car
                de blouse il n’y a pas, il n’y a point, il n’y a plus, il n’y a
                jamais eu, mais de blues il y a, c’est certain. Je ne suis pas
                rouge de colère, ni noire de rage, je suis blanche comme linge,
                blanche comme cette blouse qui a perdu sa verve, son honneur.
                Mon blanc de travail ou Mont Blanc de travail, sacerdoce ou ça
                sert d’os à ronger; quoique j’ai plutôt l’impression que ça soit
                l’OS qui nous ronge, l’Organisation de la Société, son système
                d’exploitation. J’aimerais hisser ma blouse blanche sur un
                étendard pour appeler à la paix et au respect tellement je vis
                cette machinerie hospitalière comme une violente broyeuse de
                rêve, de vocation et de bonne volonté. Cette institution
                déshumanisée qui perd la tête nous violente à coup
                d’indiscrétion, d'indifférence, d’injonctions, d’incohérences,
                de désorganisations, d’ignorance, d’échelons hiérarchiques à
                outrance.
            </p>
        ),
        image: p16,
        alt: "Massif Central",
        imagePosition: "left",
        rgb: "255, 237, 176",
    },
    {
        title: "P57 - Trajet hors du temps	- Vendredi 27 mars",
        content: (
            <div>
                <p>Confinement J11</p>
                <br />
                <p>
                    J’aime ce trajet hors du temps, sur une route quasiment
                    déserte. Les kilomètres qui s’enchaînent voient mon esprit
                    s’évader, errer dans le passé. Je repense à ma première
                    année de médecine, à ce qui m’a poussée à me lancer dans ces
                    études, dans cette aventure. Je me repasse le fil de ces
                    années. Le choc de mes débuts à l’hôpital en tout début de
                    deuxième année. La dureté de ce monde hospitalier. Moi qui
                    naïvement pensait trouver un monde plein de bienveillance,
                    car comment pouvait-il en être autrement dans un lieu fait
                    pour soigner, pour prendre soin ? Mais il n’en était rien.
                    La souffrance ambiante et partout présente chez les patients
                    avait probablement conduit les soignants à se forger une
                    carapace pour l’endurer, carapace qui créait une grande
                    dureté dans les échanges, beaucoup de violence tapie dans
                    les relations. Enfin là était ma réflexion quand je suis
                    arrivée en deuxième année de médecine. Je n’avais pas
                    cherché bien plus loin à l’époque, cherchant peut-être à me
                    préserver en ne me posant pas trop de questions pour tenter
                    d’aller au bout de ces études. Aujourd’hui, mon trajet vers
                    l’hôpital de Strasbourg me fait mettre tout cela en
                    perspective. C’est étrange comme je suis partagée entre la
                    conviction profonde d’être à ma place, en tant que médecin,
                    en tant que personne engagée dans cette mission du soin, et
                    ce sentiment de mal-être que j’ai lorsque j’ai l’impression
                    de cautionner l’imposture de notre système qui prétend
                    soigner mais qui essaye en fait de vendre de la santé, de
                    lutter contre la maladie et la mort, mais qui est à mille
                    lieux de la vie. Si au début de mes études de médecine j’ai
                    accepté l’idée que les soignants s’étaient endurcis au
                    contact de la souffrance et de la maladie, je remets
                    totalement cet avis en question aujourd’hui. Je suis
                    convaincue que nous pourrions faire autrement, être plus
                    humains, plus épanouis et plus heureux, dans un système qui
                    nous aide à cela, à faire ressortir le meilleur de nous
                    même.
                </p>
            </div>
        ),
        image: p57,
        alt: "Victoria Peak, Hong Kong",
        imagePosition: "right",
        rgb: "197, 230, 164",
    },
    {
        title: "P71 - Sortir de sa zone de confort	 1 - Mardi 31 mars",
        content: (
            <div>
                <p>Confinement J15 </p>
                <br />
                <p>
                    Strasbourg, quatrième jour à l’hôpital. Aujourd’hui, je me
                    sens un peu mieux, un peu moins perdue, un peu moins
                    inutile. Même si mon intention n’est pas de jouer l’héroïne
                    de service, loin de moi cette idée, je ne suis personne pour
                    y prétendre, et ça n’est pas du tout sain, je ne veux pas
                    non plus être un poids pour l’équipe et leur ajouter du
                    travail. C’est important pour moi de ne pas être venue là en
                    vain pour eux. Sûr que ça n’est pas en vain pour moi, j’ai
                    déjà appris énormément. Mais je ne voudrais pas non plus
                    être là pour « profiter » simplement, je voudrais qu’il y
                    ait un partage, un échange, un équilibre, un juste milieu.
                    Et chaque jour qui passe et qui m’apporte un petit peu plus
                    d’assurance et de confiance me rapproche un peu plus de cet
                    équilibre.
                </p>
                <br />
                <p>
                    Incroyable comme le fait de sortir de sa zone de confort est
                    tellement enrichissant. Être face à l’inconnu, face à ses
                    préconçus, à ses idées toutes faites. Et puis se confronter
                    à la réalité, s’y frotter, s’y acclimater, s’en accommoder.
                    Et, si tant est qu’on laisse un peu son ego de côté et ses
                    certitudes, voir venir à soi tant de beaux apprentissages,
                    tant de découvertes sur l’autre et sur soi-même. Puissé-je
                    ne jamais oublier cela, toujours chercher à sortir de sa
                    zone de confort par moment et à se confronter à l’inconnu,
                    pour trouver l’équilibre entre la confiance et l’inconnu,
                    entre l’habitude et la découverte.
                </p>
            </div>
        ),
        image: p71,
        alt: "Chambre de garde, Centre Jean Perrin",
        imagePosition: "left",
        rgb: "220, 220, 220",
    },
    {
        title: "P91 - Sortir de sa zone de confort	 2 - Dimanche 5 avril",
        content: (
            <div>
                <p>Confinement J20 </p>
                <br />
                <p>
                    Pendant ce temps-là, le projet du respirateur avance. Il y a
                    des choix à faire, des engagements à prendre, des risques
                    aussi à partager collectivement. Mais il n’y a pas
                    d’avancées, de transformations, de changements sans
                    obstacles sur le chemin. En tout cas, pas pour ce projet si
                    enthousiasmant et porteur. C’est que nombre de personnes
                    préfèrent rester dans ce qu’elles maîtrisent, dans ce qui
                    leur est familier. Pas de surprise, moins d’inconnu, plus
                    confortable. Comme un bon vieux canapé qu’on ne voudrait pas
                    quitter, un canapé qu’on connaît bien, qui nous connaît, la
                    forme de notre corps presque moulée dans son tissu,
                    enveloppant. Mais parfois, le canapé devient tellement usé,
                    qu’il devient dur, qu’il commence à faire mal, qu’on ne le
                    supporte plus même en peinture. Mais toujours cette peur de
                    l’inconnu qui nous retient. Ce n’est plus alors l’envie
                    d’être dans ce canapé, mais plutôt la peur de découvrir un
                    autre endroit, découvrir un ailleurs qu’on ne connaît pas.
                    Pas de changement en effet sans prise de risque, pas de
                    découverte sans inconnu, pas de transformation sans
                    tentative, pas de renouveau sans essai. Pourtant qu’elle est
                    riche cette sensation, cette émotion, ce plaisir de
                    découvrir, d’apprendre, de se transformer. elle ode à la vie
                    ! Tout change, tout change. Ce qui vit est mouvement, ce qui
                    vit bouge à chaque instant, la vie c’est le changement. Ce
                    qui est mort ne bouge plus, ce qui ne bouge plus est mort.
                    Alors bougeons maintenant ! Pas n’importe comment, pas à
                    tout prix, on peut le faire modérément autant que
                    prestement, on peut le faire calmement et sans affolement,
                    mais bougeons, faisons des contorsions, des allers et
                    venues, des venues et allers, ou des allers sans venues et
                    des venues sans allers, allons ensemble, allons vers de
                    nouveaux horizons meilleurs !
                </p>
            </div>
        ),
        image: p91,
        alt: "Bois de la Tête Ronde, Villiers-le-Bâcle",
        imagePosition: "right",
        rgb: "196, 246, 200",
    },
    {
        title: "P98 - Face à l’obstacle",
        content: (
            <div>
                <p>Confinement J22</p>
                <br />
                <p>
                    Aujourd’hui, nous pouvons donc dire que ce projet de
                    respirateur s’est heurté à un obstacle de taille. Nous
                    verrons dans les prochains jours si nous parvenons à le
                    franchir ensemble. Nous y travaillerons. D’obstacles, il est
                    également question dans d’autres pans de ma vie en ce
                    moment. Obstacle, embûche, digue, barrière, difficulté,
                    adversité, tant de mots pour désigner ce qui semble nous
                    empêcher d’avancer, ce qui nous barre le chemin, qui se
                    dresse sur notre route. Va-t-on s’arrêter ici ? Va-t-on
                    faire demi-tour dès que possible ? Va-t-on contourner ? Ou
                    bien va-t-on franchir, escalader? La réponse est
                    probablement différente pour chacun et pour chaque
                    situation, mais la réflexion à laquelle l’obstacle invite
                    peut en revanche être partagée. Tenter de garder la balance
                    de l’esprit, l’équanimité pour prendre la meilleure
                    décision. Ne pas se laisser gagner par la peur, ni par le
                    découragement, non encore par la déception, la colère ou la
                    haine. Bien que ces sentiments soient bien humains, et
                    méritent d’être accueillis lorsqu’ils se présentent, ils
                    peuvent cependant être dépassés, transcendés pour espérer
                    utiliser son plein potentiel face à la difficulté, pour
                    prendre la bonne décision, celle qui nous correspond ici et
                    maintenant, et sortir de l’illusion. Une fois accueillies
                    les premières émotions, il paraît intéressant de connaître
                    notre intention d’aller de l’autre côté de l’obstruction.
                    Quel est réellement notre élan? Notre motivation? Si elle
                    est minime, nous serons prêts à peu d’effort pour y
                    répondre; si elle est grande au contraire, nous serons
                    peut-être prêts à donner énormément de notre être pour
                    atteindre ce but. Ainsi, la digue qui se dresse face à nous
                    est un bon révélateur de ce qui nous anime, ce qui vit en
                    nous, ce qui nous fait bouger et nous met en action.
                    L’adversité peut également nous conduire à nous arrêter un
                    temps, observer ce qui nous entoure, ce qui se présente à
                    nous, les différentes possibilités qui se présentent, et
                    également d’accueillir et d’honorer le chemin que nous avons
                    déjà parcouru, les enseignements faits jusqu’ici. Un temps
                    pour faire une pause, un temps qui pose, un instant qui se
                    dépose, un moment qui repose. Introspection, observation,
                    intérieur et extérieur, extérieur et intérieur, pour
                    repartir fort de ses valeurs. Et lorsqu’enfin la décision
                    sera prise, quelle qu’elle soit, elle mérite là encore
                    d’être accueillie comme il se doit, avec sérénité et sans
                    animosité, sans inquiétude ou peur, la peur n’évitant pas le
                    danger. Puis, armés de toute notre volonté, nous pouvons
                    alors nous lancer et pleinement nous donner, sans réserve,
                    avec le meilleur de nous-même. Nous verrons alors qui est là
                    pour nous tenir la main, nous encourager, nous épauler, nous
                    écouter, nous respecter ou parfois aussi nous conseiller,
                    nous alerter, nous observer, ou bien encore nous
                    accompagner. Peut-être ne serait-ce que nous même. Winston
                    Churchill disait qu’ « un pessimiste voit la difficulté dans
                    toute opportunité, un optimiste voit l’opportunité dans
                    toute difficulté ». Loin de moi l’idée de foncer tête
                    baissée droit vers l’obstacle pour s’y écraser ou le refuser
                    lorsque nous arrivons à ses pieds ; en revanche, accueillir
                    la difficulté lorsqu’elle se présente inéluctablement à nous
                    peut déjà bien aider.
                </p>
            </div>
        ),
        image: p98,
        alt: "Victoria Peak, Hong Kong",
        imagePosition: "left",
        rgb: "245, 255, 245",
    },
    {
        title: "P113 - J’attends et j’espère - Dimanche 12 avril",
        content: (
            <div>
                <p>Confinement J27 </p>
                <br />
                <p>
                    13ème jour à l’hôpital de Strasbourg. J’attends et j’espère.
                    J’attends les deux prochains jours qui viennent et j’espère
                    que ces deux patients vont récupérer un peu plus de
                    musculature pour suffisamment inspirer et expirer
                    volontairement et pouvoir être extubés. Tout comme ces
                    familles attendent et espèrent. J’attends et j’espère.
                    J’attends et je ne sais pas quoi faire. J’attends sans rien
                    faire. J’attends et parfois je désespère. Et puis j’attends
                    et j’espère. J’attends et je laisse faire. J’attends en me
                    demandant ce que je peux faire, ce que je dois faire. Ce
                    point, cet instant, où le destin n’est pas entre mes mains,
                    où seul le temps pourra quelque chose à l’affaire. Pendant
                    que j’attends souvent l’inquiétude monte, le stress, la
                    tension, de ne pas voir s’accomplir ce que j’espère. C’est
                    là que parfois, pour calmer cette crainte, oublier cette
                    incertitude, cacher mon impuissance et cette nécessaire
                    attente, c’est là que parfois je commence à faire un peu
                    n’importe quoi. Je m’agite, je m’active, je cogite,
                    j’invective, souvent dans le vide, je comble le vide, je me
                    sens toute vide. Je perds le fil, j’arrête d’attendre et
                    d’espérer, je commence à courir et à tout oublier, j’ai
                    moins le sourire et plutôt envie de pleurer. Je perds
                    confiance et deviens résignée. Mais qu’avais-je alors quand
                    j’attendais et espérais que je n’ai plus ? Où est passée ma
                    foi dans l’avenir que je semble avoir perdue? Comment la
                    retrouver? Comment toujours ce sentiment faire perdurer?
                    Comment gagner en sérénité? Accepter ce que je ne peux
                    changer. C’est bien de cela qu’il s’agit.{" "}
                </p>
                <br />
                <p>
                    Peut-être que là est la clé. Si je prends du recul sur la
                    situation et que je tente de comprendre ce sur quoi je peux
                    agir et ce sur quoi je ne le peux pas. Sans doute qu’un peu
                    d’apaisement naîtra de là. C’est peut-être aussi faire
                    preuve d’humilité, accepter ma condition humaine et que je
                    n’ai ni le pouvoir, ni le droit de tout changer, que si je
                    ne peux changer le monde, c’est moi seule, si je le veux,
                    que je dois et peux transformer.
                </p>
            </div>
        ),
        image: p113,
        alt: "Furano, Hokkaido",
        imagePosition: "right",
        rgb: "245, 245, 255",
    },
    {
        title: "P139 - De l’existence supposée d’un droit de respirer - Jeudi 30 avril",
        content: (
            <div>
                <p>Confinement J45</p>
                <br />
                <p>De l’existence supposée d’un droit à respirer ? </p>
                <br />
                <p>
                    Ces derniers jours, le collectif du respirateur s’interroge
                    sur l’existence d’un droit à respirer. À l’heure du
                    Covid-19, la crainte de mourir par manque d’accès à un
                    respirateur dans nos pays riches peut paraître indécente :
                    quand dans certains pays le nombre de respirateurs se compte
                    en dizaines, en France et ailleurs en Europe il se compte en
                    dizaine de milliers. Si nombre de personnes sont conscientes
                    d’une grande inégalité d’accès à l’eau potable entre les
                    citoyens du monde, combien sont conscientes de l’inégalité
                    d’accès à un air respirable? En effet, avant cette épidémie,
                    on estimait à plusieurs millions par an, le nombre de morts
                    attribuables à la pollution de l’air, pour la grande
                    majorité d’entre eux parmi les populations les plus pauvres.
                    À l’heure d’internet, à l’aube du XXIème siècle, nous,
                    collectif, sommes convaincus que l’intelligence collective
                    et l’engagement citoyen peuvent apporter des réponses à ce
                    droit à respirer. Respirer, consciemment ou inconsciemment,
                    condition indispensable à la vie de tout être vivant.
                    Respirer, de l’échelle de la cellule à l’être vivant tout
                    entier. Respirer la vie, respirer pour vivre, plutôt que
                    survivre en tentant de respirer. C’est comme un symbole de
                    ce droit fondamental à respirer que le collectif conçoit un
                    respirateur qui peut être fabriqué sur tous les continents
                    du globe, avec le minimum de pièces possible, avec l’impact
                    écologique le plus faible, comme un acte de défense du droit
                    à respirer pour l’homme et aussi pour sa planète.
                </p>
            </div>
        ),
        image: p139,
        alt: "Lac Pavin",
        imagePosition: "left",
        rgb: "175, 225, 255",
    },
    {
        title: "P157 - Un an plus tôt - Dimanche 10 mai",
        content: (
            <div>
                <p>Confinement J55</p>
                <br />
                <p>Un an plus tôt</p>
                <br />
                <p>Chers confrères et chères consœurs,</p>
                <p> L’avenir de notre système de santé fait peur.</p>
                <p> En une médecine plus humaine j’ai besoin de croire,</p>
                <p> Sans quoi je risque fort de lui dire au revoir.</p>
                <p> J’aime mon métier de médecin,</p>
                <p>d’anesthésiste-réanimateur,</p>
                <p>
                    Mais j’ai trop souvent envie de partir et d’aller voir
                    ailleurs.
                </p>
                <br />
                <p>Parce que je vois, autour de moi,</p>
                <p> Trop de morosité et de résignation,</p>
                <p> Que la violence que nous vivons,</p>
                <p> A probablement engendrées,</p>
                <p> Écrasant de tout son poids,</p>
                <p> L’élan charitable des bonnes volontés.</p>
                <br />
                <p>Parce que je rêve de me dévouer,</p>
                <p> Mais que je n’y parviens plus sans la confiance,</p>
                <p> De l’administration et de la société, </p>
                <p>Auprès desquelles je dois sans cesse me justifier,</p>
                <p>Pour éteindre les flammes de leur défiance,</p>
                <p> Qui brûlent mes ailes et finissent par me blesser.</p>
                <br />
                <p>Au fond de moi je garde espoir,</p>
                <p> L’espoir que nous prenions part ensemble,</p>
                <p> À un changement salutaire de notre système de santé,</p>
                <p> Changement qui nous laisserait entrevoir,</p>
                <p> Un mouvement plus grand qui tous nous rassemble, </p>
                <p>Autour d’une vie meilleure dans la cité.</p>
                <br />
                <p>Je vous interpelle pour m’aider.</p>
                <p> Je vous interpelle pour espérer.</p>
                <p>
                    {" "}
                    J’interpelle notre force collective et notre responsabilité.{" "}
                </p>
                <br />
                <p>Même si je n’ai que la légitimité,</p>
                <p> De mon enthousiasme et de mon amour pour ce métier,</p>
                <p> J’ai confiance en votre dévotion,</p>
                <p>Aux malades, à la médecine ; en votre vocation.</p>
                <br />
                <p>
                    J’en appelle donc à vous tous, vous qui avez une légitimité,
                </p>
                <p>J’en appelle à la graine d’espoir qui est toujours là,</p>
                <p> Tapie quelque part en vous, même si vous êtes bien las,</p>
                <p> Cette graine d’espoir qui n’aspire qu’à s’éveiller.</p>
                <br />
                <p>Car demain je ne veux pas me réveiller,</p>
                <p>
                    En me disant qu’il est trop tard et que je n’ai rien fait.
                </p>
                <br />
                <p>Pleine d’espérance, </p>
                <br />
                <p>Margot.</p>
            </div>
        ),
        image: p157,
        alt: "Jeju-Do",
        imagePosition: "poem",
        rgb: "250, 250, 255",
    },
    {
        title: "P175 - Nouveau départ - Dimanche 8 novembre",
        content: (
            <div>
                <p>Nouveau départ</p>
                <br />
                <p>
                    C’est l’occasion de laisser mes souvenirs derrière moi. Ma
                    souffrance est toujours là. Mes doutes sur mon métier, sur
                    ma vocation sont plus que jamais présents. Mais une nouvelle
                    porte peut désormais s’ouvrir. Il fallait nécessairement que
                    je ferme une porte avant d’en ouvrir une autre. Je laisse
                    tranquille la plaie qui commence à cicatriser. La plaie de
                    la désillusion, de la déception. La sidération a laissé la
                    place à un peu plus d’énergie que l’énergie du désespoir qui
                    en a perdu plus d’un. J’ai la chance d’avoir la colère pour
                    me porter, pour m’animer, pour me donner l’envie de me
                    battre. Partir pour mieux me reconstruire. Partir pour mieux
                    me retrouver.
                </p>
                <br />
                <p>
                    Je suis en route pour Montluçon. Je commence à l’hôpital
                    demain matin.
                </p>
            </div>
        ),
        image: p175,
        alt: "Jeju-Do",
        imagePosition: "left",
        rgb: "255, 224, 148",
    },
    {
        title: "P227 - Arrêt cardiaque - Mercredi 3 mars 2021",
        content: (
            <div>
                <p>
                    2h du matin, coup de téléphone. Le patient de la chambre une
                    est en arrêt cardiaque. « Sérieux ? » ; « Qu’est-ce qu’il
                    s’est passé? »; « Je n’ai rien vu venir lors de mon tour il
                    y a deux heures ». Je déteste ça. Je suis endormie depuis
                    quoi, une demi-heure peut-être. L’adrénaline qui monte,
                    l’urgence, le stress au milieu d’une tentative d’émergence
                    du lit en sursaut. Mon corps ne sait plus où il habite. Il
                    est réveillé comme s’il allait courir un sprint et en même
                    temps encore à moitié endormi. Mon cœur s’accélère sur cette
                    mise en mouvement rapide. Mon cerveau tente de retrouver ses
                    esprits : remettre l’histoire du patient dans le bon ordre,
                    évoquer les hypothèses diagnostiques de cet arrêt cardiaque,
                    revoir l’algorithme de prise en charge en quelques secondes
                    alors que pendant ce temps-là mes bras se saisissent des
                    quelques affaires nécessaires pour aller dans la chambre du
                    patient, que mes pieds se mettent dans mes sabots roses, et
                    que mes jambes marchent vigoureusement. Ouvrir la porte en
                    me saisissant du masque FFP2 que j’ai laissé sur la poignée.
                    Quand je débarque dans la chambre peu de temps après le coup
                    de fil, c’est vraiment rassurant de trouver une équipe que
                    je connais bien qui a déjà entrepris le déroulé de la prise
                    en charge de l’arrêt cardiaque sans encombre, avec toute
                    l’équipe de nuit présente dans et en dehors de la chambre
                    pour aider en même temps que veiller sur les autres
                    patients. C’est là que l’esprit et le corps se dissocient
                    pour mieux prendre en charge l’urgence, considérant là le
                    patient comme un objet, et plus vraiment comme un sujet, une
                    fois réglée la question de « faut-il réanimer ce patient ? »
                    qui a déjà heureusement été discutée en amont, en journée,
                    de façon collégiale. Mon esprit donc est complètement
                    dissocié de la personne qui est morte à moins qu’on ne
                    parvienne à la réanimer, sinon je ne peux pas agir en cet
                    instant. Il s’agit de ne pas perdre de temps, de dérouler le
                    protocole en équipe sans accros si possible, masser,
                    ventiler, compter, choquer. Cette nuit, pas d’intubation ni
                    d’injection d’adrénaline, le patient a rapidement retrouvé
                    un rythme cardiaque efficace après le premier choc
                    électrique externe et s’est réveillé pour reprendre une
                    ventilation spontanée.
                </p>
            </div>
        ),
        image: p227,
        alt: "Fushimi Inari, Kyoto",
        imagePosition: "right",
        rgb: "252, 190, 150",
    },
    {
        title: "P261 - Prendre soin - Mercredi 26 mai",
        content: (
            <div>
                <p>
                    Qu’est-ce que prendre soin? Qu’est-ce que prendre soin en
                    cette période d’urgence sanitaire ? Je me demande. Quelle
                    est la place et la dimension du prendre soin dans la
                    politique actuelle ? Car, je ne vois pas comment la
                    politique peut être exercée si la dimension du prendre soin
                    de la population n’a pas d’espace, n’a pas de place, n’est
                    pas nommée, n’est pas conscientisée. N’est-ce pas le plus
                    important que la politique prenne soin de sa population? Le
                    reste en découle, la sécurité, la politique extérieure, les
                    nances, tout doit être au service du prendre soin, sans quoi
                    cela n’a pas de sens. D’autant que prendre soin, ça n’est
                    pas « assister » les gens et les transformer en « assistés »
                    comme certains hommes et certaines femmes politiques aiment
                    à nous le laisser penser. Non, prendre soin d’une personne
                    que l’on respecte, c’est prendre soin de son autonomie
                    aussi, c’est donc faire avec elle pour la soutenir dans les
                    moments de vulnérabilités, et l’accompagner à devenir, ou à
                    rester autonome. C’est bien cela prendre soin, bien plus
                    grand, riche et noble que la vision dévoyée que les médias
                    et politiques voudraient nous inculquer.
                </p>
            </div>
        ),
        image: p261,
        alt: "Le Grand Suchet, Puy de Dôme",
        imagePosition: "left",
        rgb: "180, 230, 160",
    },
    {
        title: "P265 - Se tromper - Samedi 19 juin",
        content: (
            <div>
                <p>
                    Fatiguée de la semaine. Peut-être. Etre oiseau de mauvais
                    augure chaque jour, et passer plusieurs heures avec les
                    familles, les patients, les équipes pour trouver les mots,
                    qui manquent toujours, ou qui sont souvent à côté. Trouver
                    les mots pour dire l’indicible, qu’on ne peut plus rien,
                    qu’il n’y a plus d’espoir. Dire qu’on va accompagner, que
                    nous allons tout faire pour prendre en charge la souffrance
                    physique. Mais la souffrance morale, nous ne savons pas
                    vraiment faire. C’est dans ce climat d’usure de la semaine,
                    que je fais une grosse boulette. Je me trompe de numéro de
                    téléphone pour annoncer un décès cette nuit à une heure et
                    demi du matin. Heureusement je m’en rends compte au moment
                    où la personne décroche le téléphone, je réalise que je me
                    suis trompée. Mais alors je rentre dans un état de
                    sidération, je ne sais plus vraiment quoi dire. Je vérifie
                    qui j’ai appelé. Cette personne me demande si je me suis
                    trompée. Je suis incapable de dire que oui, les mots se
                    bousculent dans ma tête. Oui je me suis trompée au départ.
                    Mais non vous êtes bien la fille de Mme X. Mais je suis
                    désolée, je ne devais pas vous appeler. Votre maman va mieux
                    cette nuit, son état a continué de s’améliorer sur la soirée
                    et le début de nuit. « Vous me mentez madame, ma maman est
                    décédée et vous n’osez pas me le dire ». La personne au bout
                    du téléphone est choquée et aussi sidérée que moi je crois.
                    Comment ai-je pu faire ça ? D’habitude, je vérifie toujours
                    trois fois le numéro et la personne que j’appelle avant de
                    passer ce type de coup de téléphone annonciateur du décès
                    d’un patient. Mais qu’est-ce que j’ai fait ? Qu’est-ce qui
                    s’est passé? Et pourquoi n’ai-je pas dit oui lorsque la
                    personne m’a demandé si je m’étais trompée? Je venais de me
                    lever quelques dizaines de minutes après m’être couchée. Je
                    suis sonnée. Sonnée de ma connerie. Dans un état un peu
                    hagard. J’ai profondément honte. Je m’en veux. Comment ai-je
                    pu faire ça à cette dame ? L’équipe est là pour prendre le
                    relai, parler à cette dame pour tenter de la rassurer même
                    si c’est bien tard pour cela. Je ne peux pas remonter le
                    temps. C’est trop tard, c’est arrivé. Je n’ai pas eu la
                    clarté d’esprit et le courage d’assumer mon erreur tout de
                    suite, sans attendre, sans vouloir minimiser, même si j’ai
                    pensé bien faire au départ pour rassurer la dame. Mauvaise
                    idée. Très mauvaise idée.
                </p>
            </div>
        ),
        image: p265,
        alt: "Balade sous la neige, Puy de Dôme",
        imagePosition: "right",
        rgb: "235, 235, 235",
    },
    {
        title: "P319 - Epilogue",
        content: (
            <div>
                <p>Confinés qu’ils étaient,</p>
                <p> Les êtres humains se demandèrent inquiets,</p>
                <p> Ce qui faisait pour eux humanité.</p>
                <br />
                <p> Isolés,</p>
                <p> Ils ne pouvaient plus marcher sur personne,</p>
                <p> Masqués,</p>
                <p> Ils ne pouvaient plus dévisager personne.</p>
                <br />
                <p> Et pourtant ils continuaient de marcher,</p>
                <p> Côte à côte,</p>
                <p> Ensemble.</p>
                <br />
                <p>Et pourtant ils continuaient à se regarder,</p>
                <p> Deux à deux,</p>
                <p>Dans le blanc des yeux.</p>
                <br />
                <p> Ils comprirent alors qu’ils avançaient,</p>
                <p> Sur un chemin propre à chacun,</p>
                <p> En même temps que partagé par tous,</p>
                <p> Sur un fil,</p>
                <p> Le fil de la vie.</p>
                <br />
                <p> Chacun à ses rythmes, </p>
                <p>Chacun à ses places.</p>
                <p> Unis par le fait de marcher,</p>
                <p>Pour conserver l’équilibre.</p>
                <br />
                <p> Observant ce maillage, </p>
                <p>Ils virent des dessins,</p>
                <p> Faits de fils de couleurs et de nuances de gris.</p>
                <br />
                <p> Ils entendirent de la musique,</p>
                <p> Qui venait des fils qui vibrent,</p>
                <p> Et s’accordent entre eux.</p>
                <br />
                <p>
                    Ils sentirent la chaleur au contact des uns et des autres,
                </p>
                <p>Quand ils s’approchaient doucement,</p>
                <p> Pour se toucher avec délicatesse,</p>
                <p>Se caresser et s’équilibrer.</p>
                <br />
                <p>
                    Lorsqu’ils résonnaient si forts qu’ils voulaient faire corps
                    ensemble,
                </p>
                <p> Ils se liaient pour un instant,</p>
                <p> Voire pour quelques temps,</p>
                <p> Faisant jusqu’à naître de nouveaux fils.</p>
                <br />
                <p> S’ils avançaient parfois plus vite seuls,</p>
                <p> Ils allaient plus loin ensemble.</p>
                <p> Et certains s’éteignaient en route,</p>
                <p> Arrivés au bout de leur fil.</p>
                <br />
                <p> Pour que chacun puisse avancer sereinement sur son fil,</p>
                <p> Ils comprirent qu’ils devaient tous prendre soin,</p>
                <p> D’eux-mêmes sur leur fil,</p>
                <p> De l’autre sur son fil à côté,</p>
                <p> Du vide contenant les fils,</p>
                <p> Pour laisser de l’espace pour avancer.</p>
                <br />
                <p>Chemin faisant,</p>
                <p> Les fils devenaient de plus en plus nombreux,</p>
                <p>
                    Il était maintenant quasi impossible d’avancer sur les fils,
                </p>
                <p> Sans se heurter,</p>
                <p> Sans tomber,</p>
                <p> En regardant de tous côtés.</p>
                <br />
                <p>
                    C’est alors que les êtres humains se mirent à communiquer,
                </p>
                <p> Écouter surtout,</p>
                <p> Parler un peu,</p>
                <p> Pour garder l’équilibre,</p>
                <p> Et continuer d’avancer,</p>
                <p> Ensemble.</p>
            </div>
        ),
        image: p319,
        alt: "Echappées belles, Puy de Dôme",
        imagePosition: "poem",
        rgb: "200, 230, 255",
    },
];

export default bookExtracts;
