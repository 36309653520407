import React from "react";
import DynamicIcon from "../DynamicIcon";

const PetalLink = ({
    title,
    link,
    icon,
    logo,
    logoUrl,
    fontSize,
    logoLabel,
}) => {
    const maxWidth = 320;

    const linkStyle = {
        fontSize,
        maxWidth: `${maxWidth}px`,
        whiteSpace: "nowrap", // Ensure all content stays on the same line
        overflow: "hidden", // Hide overflow if the text is too long
        textOverflow: "ellipsis", // Add ellipsis for overflowing text
        display: "flex",
        flexDirection: "column", // Align items horizontally
        alignItems: "center", // Vertically center items
        textAlign: "center",
        gap: "0.2em", // Add spacing between elements
        lineHeight: "1",
    };

    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex cursor-pointer text-gray-700 transition-colors duration-200 hover:text-gray-900"
            style={linkStyle}
        >
            <div className="flex items-center gap-1">
                {/* Icon */}
                <DynamicIcon iconName={icon} />

                {/* Title */}
                <span
                    style={{ fontSize: `calc(${fontSize})` }}
                    className="text-blue-800"
                >
                    {title}
                </span>
            </div>
            {/* Logo (if present) */}
            {logo && (
                <img
                    src={logoUrl}
                    alt={title}
                    label={logoLabel}
                    style={{
                        // width: `calc(${fontSize} * 1.5)`,
                        height: `calc(${fontSize} * 1.5)`,
                        marginBottom: "0.1em",
                        maxWidth: "50px",
                    }}
                />
            )}
        </a>
    );
};

export default PetalLink;
